body,
#root,
#__next {
  min-width: 100%;
}

a {
  text-decoration: none;
}

/* Component Specific */

.ImageZoom {
  inset: 0;
  display: none;
}

.ImageZoom[open] {
  display: block;
}

.ot-floating-button__front {
  background-image: url('/cookie-pro.png') !important;
}

/* Utils */

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* force the image to fill its container, ignoring its aspect ratio */
.embed-offer-image img {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  min-width: 100%;
  min-height: 100%;
}
